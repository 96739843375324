<template>
	<div class="PituitaryPumplist">
		<div class="conditionArea">
			<el-form ref="AlarmRecord" :data="medicalPhysicalCondition">
				<span>体征记录</span>
				<el-form-item label="填写时间:" class="conditionAreaForm inner_data">
					<el-date-picker
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						v-model="fillDates"
					></el-date-picker>
					<el-button type="primary" style="margin-left: 20px;background-color:#4870FF;" @click="doPhysicalSearch">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="Listmain">
			<el-table v-if="patientGender === 1" style="width: 100%" :data="physicalRecordData" ref="AlarmRecord">
				<el-table-column label="序号" type="index" min-width="50"></el-table-column>
				<el-table-column label="第一性征" align="center">
					<el-table-column prop="answer1" label="晨勃现象" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer1 === 1">存在</span>
							<span v-if="scope.row.answer1 === 2">消失</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer2" label="性生活频率（含自慰）" min-width="150" align="center">
						<template slot-scope="scope">
							<span>{{ scope.row.answer2 }}次/月</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer3" label="勃起功能" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer3 === 1">正常</span>
							<span v-if="scope.row.answer3 === 2">疲软</span>
							<span v-if="scope.row.answer3 === 3">障碍</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer4" label="睾丸大小" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer4 === 1">增大</span>
							<span v-if="scope.row.answer4 === 2">萎缩</span>
							<span v-if="scope.row.answer4 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer5" label="乳腺变化" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer5 === 1">增大</span>
							<span v-if="scope.row.answer5 === 2">萎缩</span>
							<span v-if="scope.row.answer5 === 3">无变化</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="第二性征" align="center">
					<el-table-column prop="answer6" label="头发变化" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer6 === 1">增加</span>
							<span v-if="scope.row.answer6 === 2">减少</span>
							<span v-if="scope.row.answer6 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer7" label="胡须浓度变化" min-width="120" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer7 === 1">增加</span>
							<span v-if="scope.row.answer7 === 2">减少</span>
							<span v-if="scope.row.answer7 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer8" label="阴毛变化" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer8 === 1">增加</span>
							<span v-if="scope.row.answer8 === 2">减少</span>
							<span v-if="scope.row.answer8 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer9" label="喉结变化" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer9 === 1">增大</span>
							<span v-if="scope.row.answer9 === 2">变小</span>
							<span v-if="scope.row.answer9 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer10" label="对异性兴趣" min-width="120" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer10 === 1">增加</span>
							<span v-if="scope.row.answer10 === 2">减退</span>
							<span v-if="scope.row.answer10 === 3">无变化</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="身体素质" align="center">
					<el-table-column prop="answer11" label="力量" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer11 === 1">增强</span>
							<span v-if="scope.row.answer11 === 2">维持</span>
							<span v-if="scope.row.answer11 === 3">减弱</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer12" label="体能" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer12 === 1">增强</span>
							<span v-if="scope.row.answer12 === 2">维持</span>
							<span v-if="scope.row.answer12 === 3">减弱</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer13" label="记忆力" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer13 === 1">增强</span>
							<span v-if="scope.row.answer13 === 2">维持</span>
							<span v-if="scope.row.answer13 === 3">减弱</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer14" label="是否易怒" min-width="90" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer14 === 1">是</span>
							<span v-if="scope.row.answer14 === 0">否</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="填写时间" prop="createTime" min-width="100" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime | splitDate }}</span>
					</template>
				</el-table-column>
			</el-table>
			<el-table v-if="patientGender === 0" style="width: 100%" :data="physicalRecordData" ref="AlarmRecord">
				<el-table-column label="序号" type="index" min-width="150"></el-table-column>
				<el-table-column label="第一性征" align="center">
					<el-table-column prop="answer1" label="月经周期" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer1 === 1">规律</span>
							<span v-if="scope.row.answer1 === 2">异常</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer2" label="乳腺变化" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer3 === 1">增大</span>
							<span v-if="scope.row.answer3 === 2">萎缩</span>
							<span v-if="scope.row.answer3 === 3">无变化</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="第二性征" align="center">
					<el-table-column prop="answer3" label="头发变化" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer3 === 1">增加</span>
							<span v-if="scope.row.answer3 === 2">减少</span>
							<span v-if="scope.row.answer3 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer4" label="阴毛变化" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer4 === 1">增加</span>
							<span v-if="scope.row.answer4 === 2">减少</span>
							<span v-if="scope.row.answer4 === 3">无变化</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer5" label="对异性兴趣" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer5 === 1">增加</span>
							<span v-if="scope.row.answer5 === 2">减退</span>
							<span v-if="scope.row.answer5 === 3">无变化</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="身体素质" align="center">
					<el-table-column prop="answer6" label="力量" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer6 === 1">增强</span>
							<span v-if="scope.row.answer6 === 2">维持</span>
							<span v-if="scope.row.answer6 === 3">减弱</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer7" label="体能" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer7 === 1">增强</span>
							<span v-if="scope.row.answer7 === 2">维持</span>
							<span v-if="scope.row.answer7 === 3">减弱</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer8" label="记忆力" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer8 === 1">增强</span>
							<span v-if="scope.row.answer8 === 2">维持</span>
							<span v-if="scope.row.answer8 === 3">减弱</span>
						</template>
					</el-table-column>
					<el-table-column prop="answer9" label="是否易怒" min-width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.answer9 === 1">是</span>
							<span v-if="scope.row.answer9 === 2">否</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="填写时间" prop="createTime" min-width="100" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime | splitDate }}</span>
					</template>
				</el-table-column>
			</el-table>
			<div v-if="patientGender === null" class="blank-txt">暂无数据</div>
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="medicalPhysicalCondition.page"
				:page-sizes="[5, 10, 20, 50]"
				:page-size="medicalPhysicalCondition.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="gnrhPhysicalTotal"
				style="text-align: right;margin-right: 10px;margin-top: 5px;"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PatientGnrhPhysicals',
	data() {
		return {
			medicalPhysicalCondition: {
				page: 1,
				size: 5,
				userUuid: this.puid,
				endVisitDate: '',
				startVisitDate: ''
			},
			total:null,
			physicalRecordData: [],
			gnrhPhysicalTotal: 0,
			fillDates: [],
			patientGender: null
		};
	},
	props: {
		puid: String
	},
	created() {
		this.$axios.get('/api/web/user/detail/' + this.puid).then(res => {
			if (res.data.code === 200) {
				this.patientGender = res.data.data.gender;
				this.getGnrhPhysicalRecordList();
			}
		});
	},
	methods: {
		doPhysicalSearch() {
			this.physicalRecordData.splice(0, this.physicalRecordData.length);
			if (this.fillDates !== null && this.fillDates.length > 1) {
				this.medicalPhysicalCondition.startVisitDate = this.fillDates[0] + ' 00:00:00';
				this.medicalPhysicalCondition.endVisitDate = this.fillDates[1] + ' 23:59:59';
			}else{
				this.medicalPhysicalCondition.startVisitDate = '';
				this.medicalPhysicalCondition.endVisitDate = '';
			}
			// console.log(this.medicalPhysicalCondition)
			this.getGnrhPhysicalRecordList();
		},
		getGnrhPhysicalRecordList() {
			this.$axios.post('/api/web/patientGnrhPhysicalRecord/page', this.medicalPhysicalCondition).then(res => {
				if (res.data.code === 200) {
					this.physicalRecordData = res.data.data;
					this.gnrhPhysicalTotal = res.data.data !== null ? res.data.pageData.totalPages : 0;
				} else {
					this.$message({
						message: '体征记录获取失败',
						type: 'error'
					});
				}
			});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.medicalPhysicalCondition.size = newSize;
			this.getGnrhPhysicalRecordList();
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.medicalPhysicalCondition.page = newPage;
			this.getGnrhPhysicalRecordList();
		},
	}
};
</script>

<style scoped>
.PatientInformation {
	height: 60px;
	border-radius: 10px;
	background-color: #fff;
}
/deep/.PatientInformationForm {
	margin-bottom: 0 !important;
	margin-left: 30px;
	float: left;
	display: block;
}
/deep/.el-form-item--small .el-form-item__label {
	line-height: 60px;
	float: left;
}
/deep/.el-form-item--small .el-form-item__content {
	line-height: 60px;
	float: left;
}
.PituitaryPumplist {
	background-color: #fff;
	height: auto;
	padding-bottom: 20px;
	margin-top: 16px;
	border-radius: 10px;
}
.conditionArea {
	width: 100%;
	float: left;
}
.conditionArea span {
	float: left;
	line-height: 60px;
	font-weight: bold;
	margin-left: 30px;
}
.conditionAreaForm {
	float: right;
	margin-bottom: 0 !important;
	margin-right: 20px;
}
/deep/.conditionAreaForm .el-input__inner {
	width: 150px;
}
/deep/.inner_data .el-input__inner {
	width: 300px;
}
/deep/.el-table td:first-child,
.el-table th:first-child {
	text-align: center !important;
}
.blank-txt {
	line-height: 20px;
	font-size: 12px;
	color: #909399;
}
</style>
