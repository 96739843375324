<template>
    <div class="PituitaryPumplist">
        <div class="conditionArea">
            <el-form :data="lifestyleCondition">
                <span>生活方式记录</span>
                <el-form-item label="填写时间:" class="conditionAreaForm inner_data">
                    <el-date-picker
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            v-model="fillDates"
                    ></el-date-picker>
                    <el-button type="primary" style="margin-left: 20px;background-color:#4870FF;" @click="doLifestyleSearch">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="Listmain">
            <el-table style="width: 100%" :data="lifestyleRecordData" border>
                <el-table-column label="序号" type="index" min-width="80"></el-table-column>
                <el-table-column label="填写时间" prop="createTime" min-width="90" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.createTime | splitDate }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="体重" prop="weight" min-width="80" align="center">
                </el-table-column>
                <el-table-column label="睡眠时长" align="center" prop="sleepHours" min-width="90" show-overflow-tooltip></el-table-column>
                <el-table-column label="运动" align="center">
                    <el-table-column prop="trainWay" label="方式" min-width="80" align="center"></el-table-column>
                    <el-table-column prop="trainMinutes" label="时长" min-width="80" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="药物" align="center">
                    <el-table-column prop="medicalType" label="药物种类" min-width="80" align="center"></el-table-column>
                    <el-table-column prop="medicalDosage" label="服用方法" min-width="80" align="center"></el-table-column>
                </el-table-column>
                <el-table-column label="饮食" align="center">
                    <el-table-column label="早餐" align="center">
                        <el-table-column prop="breakfastTime" label="时间" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="breakfastDesc" label="内容" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="breakfastWeight" label="重量" min-width="80" align="center"></el-table-column>
                    </el-table-column>
                    <el-table-column label="中餐" align="center">
                        <el-table-column prop="lunchTime" label="时间" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="lunchDesc" label="内容" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="lunchWeight" label="重量" min-width="80" align="center"></el-table-column>
                    </el-table-column>
                    <el-table-column label="晚餐" align="center">
                        <el-table-column prop="dinnerTime" label="时间" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="dinnerDesc" label="内容" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="dinnerWeight" label="重量" min-width="80" align="center"></el-table-column>
                    </el-table-column>
                    <el-table-column label="零食" align="center">
                        <el-table-column prop="snackTime" label="时间" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="snackDesc" label="内容" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="snackWeight" label="重量" min-width="80" align="center"></el-table-column>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="血糖检测（指血）" align="center">
                    <el-table-column prop="bloodSugarCheckTime" label="时间" min-width="90" align="center"></el-table-column>
                    <el-table-column prop="bloodSugarCheckResult" label="结果" min-width="80" align="center"></el-table-column>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="lifestyleCondition.page"
                    :page-sizes="[5, 10, 20, 50]"
                    :page-size="lifestyleCondition.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="csiiLifestyleTotal"
                    style="text-align: right;margin-right: 10px;margin-top: 5px;"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PatientCsiiLifestyles",
        data() {
            return {
                lifestyleCondition: {
                    page: 1,
                    size: 5,
                    userUuid: this.puid,
                    endVisitDate: '',
                    startVisitDate: ''
                },
                total:null,
                lifestyleRecordData: [],
                csiiLifestyleTotal: 0,
                fillDates: []
            }
        },
        props: {
            puid: String
        },
        created() {
            this.getCsiiLifestyleList();
        },
        methods: {
            // 监听 pagesize 改变的事件
            handleSizeChange(newSize) {
                this.lifestyleCondition.size = newSize;
                this.getCsiiLifestyleList();
            },
            //监听 页码值 改变的事件
            handleCurrentChange(newPage) {
                this.lifestyleCondition.page = newPage;
                this.getCsiiLifestyleList();
            },
            doLifestyleSearch() {
                this.lifestyleRecordData.splice(0, this.lifestyleRecordData.length);
                if (this.fillDates !== null && this.fillDates.length > 1) {
                    this.lifestyleCondition.startVisitDate = this.fillDates[0] + ' 00:00:00';
                    this.lifestyleCondition.endVisitDate = this.fillDates[1] + ' 23:59:59';
                }else{
                    this.lifestyleCondition.startVisitDate = '';
                    this.lifestyleCondition.endVisitDate = '';
                }
                this.getCsiiLifestyleList();
            },
            getCsiiLifestyleList() {
                this.$axios.post('/api/web/patientCsiiLifestyleRecord/page', this.lifestyleCondition)
                    .then(res => {
                        if (res.data.code === 200) {
                            this.lifestyleRecordData = res.data.data;
                            this.csiiLifestyleTotal = res.data.data !== null ? res.data.pageData.totalPages : 0;
                        } else {
                            this.$message({
                                message: '生活方式获取失败',
                                type: 'error'
                            });
                        }
                    });
            }
        }
    }
</script>

<style scoped>
    .PatientInformation {
        height: 60px;
        border-radius: 10px;
        background-color: #fff;
    }
    /deep/.PatientInformationForm {
        margin-bottom: 0 !important;
        margin-left: 30px;
        float: left;
        display: block;
    }
    /deep/.el-form-item--small .el-form-item__label {
        line-height: 60px;
        float: left;
    }
    /deep/.el-form-item--small .el-form-item__content {
        line-height: 60px;
        float: left;
    }
    .PituitaryPumplist {
        background-color: #fff;
        height: auto;
        padding-bottom: 20px;
        margin-top: 16px;
        border-radius: 10px;
    }
    .conditionArea {
        width: 100%;
        float: left;
    }
    .conditionArea span {
        float: left;
        line-height: 60px;
        font-weight: bold;
        margin-left: 30px;
    }
    .conditionAreaForm {
        float: right;
        margin-bottom: 0 !important;
        margin-right: 20px;
    }
    /deep/.conditionAreaForm .el-input__inner {
        width: 150px;
    }
    /deep/.inner_data .el-input__inner {
        width: 300px;
    }
    /deep/.el-table td:first-child,
    .el-table th:first-child {
        text-align: center !important;
    }
    .blank-txt {
        line-height: 20px;
        font-size: 12px;
        color: #909399;
    }
</style>