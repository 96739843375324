import {
	getTime
} from "../../utils/index.js"
import {
	parseDate
} from "../../utils/convert.js"
import PatientPlan from "../../components/PatientPlan";
import PatientGnrhMedicals from "../../components/PatientGnrhMedicals";
import PatientGnrhPhysicals from "../../components/PatientGnrhPhysicals";
import PatientCsiiMedicals from "../../components/csii/PatientCsiiMedicals";
import PatientCsiiLifestyles from "../../components/csii/PatientCsiiLifestyles";
import PatientCsiiWarningRecords from "../../components/csii/PatientCsiiWarningRecords";
import PatientInfusionDataChart from "../../components/csii/PatientInfusionDataChart.vue";

export default {
	name: "PatientDetail",
	components: {
		PatientPlan,
		PatientGnrhMedicals,
		PatientGnrhPhysicals,
		PatientCsiiMedicals,
		PatientCsiiLifestyles,
		PatientCsiiWarningRecords,
		PatientInfusionDataChart
	},
	data() {
		return {
			//报警数据
			getPatientDetail: {
				page: 1,
				size: 5,
				userType: Number,
				uuid: '',
			},
			activeName: 'first',
			tableData: [],
			// CgmTableData:[],
			// CgmDeviceList:{},
			AlarmRecord: [],
			getPatientDetailUuid: '',
			userType: Number,
			total: 0,

			infusionPlanData: [],
			infusionPlanTotal: 0,

			infusionData: [],
			//输注记录
			infusionCondition: {
				page: 1,
				size: 5,
				userUuid: '',
				startBelongDate: '',
				endBelongDate: ''
			},
			infusionTotal: 0,
			infusionBelogDates: [],

			dialogTitle: '日输注记录',
			dialogFlag: false,
			dialogInfustionDate: '',
			dialogInfustionDayTotal: 0,
			dialogInfustionLastTime: '',
			charts: {},
			oneDayArr: [
				"00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45",
				"02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45",
				"04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
				"06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45",
				"08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45",
				"10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
				"12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45",
				"14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45",
				"16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
				"18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45",
				"20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45",
				"22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"
			],
			oneDayVals: [],
			cgmreportShow: false,
			firstType: false,
			secondType: false,
			thirdType: false,
			sessionStorageUserType: '',
			sessionStorageuserUuid: '',
			monthSel: '',
			dialogMonthFlag: false,
			dialogInfustionMonth: '',
			dialogInfustionMonthTotal: '',
			oneMonthArr: [],
			monthChart: {},
			monthData: [],
			//cgm 分享患者uuid
			ufu: null,
			patientname: null,
			setTimeData: '',
			downloadData: {
				endMeasureTime: '',
				startMeasureTime: '',
				userUuid: '',
			},
			deviceName: ''
		};
	},
	created() {
		this.getPatientDetailUuid = this.$route.query.getPatientDetailUuid;
		// console.log(this.getPatientDetailUuid )
		this.sessionStorageUserType = sessionStorage.getItem('userType');
		this.sessionStorageuserUuid = sessionStorage.getItem('userUuid');
		this.ufu = this.$route.query.ufu ? this.$route.query.ufu : this.$route.query.getPatientDetailUuid;
		this.userType = this.$route.query.userType;
		this.activeName = this.$route.query.activeName;

		console.log('this.activeName', this.activeName);

		this.PatientDetail();
		this.getInfusionData();
		this.getAlarmRecord();
		this.getDevice();
	},
	mounted() {
		sessionStorage.setItem("detail", true);
	},
	methods: {
		getDevice() {
			let deviceType = this.activeName === 'first' ? '1' : '2';
			this.$axios.get("/api/web/patientDevice/findByDetail/" + this.ufu + "/" + deviceType).then(
				response => {
					console.log('rrrsss', response);
					if (response.data.code === 200) {
					  this.deviceName = response.data.data.deviceName;
					}
				}
			);
		},
		//tab标签默认项
		handleClick(tab, event) {
			console.log(tab, event);
		},
		//获取患者信息
		PatientDetail() {
			// console.log(this.getPatientDetailUuid)
			if (this.getPatientDetailUuid == null) {
				this.getPatientDetailUuid = this.sessionStorageuserUuid
			}
			console.log('getPatientDetailUuid', this.getPatientDetailUuid)
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.$axios
				.get("/api/web/user/detail/" + this.getPatientDetailUuid)
				.then(response => {
					this.tableData = response.data.data;
					for (let i = 0; i < this.tableData.deviceList.length; i++) {
						if (this.tableData.deviceList[i].deviceType === 1 && this.tableData.deviceList[i].status === 1) {
							this.firstType = true
							this.activeName = 'first';
						} else if (this.tableData.deviceList[i].deviceType === 2 && this.tableData.deviceList[i].status === 1) {
							this.secondType = true
							this.activeName = 'second';
						} 
						// else if (this.tableData.deviceList[i].deviceType == 3 || this.tableData.deviceList[i]
						// 	.deviceType == 4) {
						// 	this.thirdType = true
						// 	this.activeName = 'third';
						// }
					}
				})
				.catch(e => {
					this.$message({
						message: e,
						type: "error"
					});
				});
		},
		// 输注记录 监听 pagesize 改变的事件
		handleSizeChange1(newSize) {
			this.infusionCondition.size = newSize;
			this.getInfusionData()
		},
		//  输注记录 监听 页码值 改变的事件
		handleCurrentChange1(newPage) {
			this.infusionCondition.page = newPage;
			this.getInfusionData()
		},
		// 报警记录  监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getPatientDetail.size = newSize;
			this.getAlarmRecord()
		},
		// 报警记录  监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getPatientDetail.page = newPage;
			this.getAlarmRecord()
		},
		/*获取报警信息数据*/
		getAlarmRecord() {
			this.AlarmRecord.splice(0, this.AlarmRecord.length); //清空数组
			this.getPatientDetail.userUuid = this.getPatientDetailUuid;

			let warningList = this.getPatientDetail;
			this.$set(warningList, "sortTypeName", "warningTime");

			this.$axios({
				method: "post",
				url: "/api/web/patientWarning/page", // 接口地址
				data: warningList
			}).then(res => {
				if (res.data.code === 200) {
					this.AlarmRecord = res.data.data;
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("报警信息请求失败");
			});
		},
		//输注记录
		getInfusionData() {
			let vm = this;
			vm.infusionCondition.userUuid = vm.getPatientDetailUuid;
			console.log(vm.infusionCondition.userUuid)
			vm.$axios({
				method: "post",
				url: "/api/web/patientInfusionData/page",
				data: vm.infusionCondition
			}).then(res => {
				if (res.data.code === 200) {
					vm.infusionData = res.data.data;
					vm.infusionTotal = res.data.pageData.totalElements;
				} else {
					vm.$message({
						message: "输注数据获取失败",
						type: "error",
					});
				}
			});
		},
		doInfusionSearch() {
			this.infusionData.splice(0, this.infusionData.length);
			if (this.infusionBelogDates !== null && this.infusionBelogDates.length > 1) {
				this.infusionCondition.startBelongDate = this.infusionBelogDates[0] + ' 00:00:00';
				this.infusionCondition.endBelongDate = this.infusionBelogDates[1] + ' 23:59:59';
			} else {
				this.infusionCondition.startBelongDate = '';
				this.infusionCondition.endBelongDate = '';
			}
			this.getInfusionData();
		},
		doMonthSearch() {
			if (this.monthSel === '') {
				this.$message({
					message: "请选择月份",
					type: "error",
				});
				return;
			}

			this.oneMonthArr = [];
			this.monthData = [];

			let currDate = new Date(this.monthSel + '-01');
			currDate.setMonth(currDate.getMonth() + 1);
			let lastDay = currDate.setDate(0);

			this.$axios({
				method: "post",
				url: "/api/web/patientInfusionData/listMonth",
				data: {
					startBelongDate: parseDate(new Date(this.monthSel + '-01')) + ' 00:00:00',
					endBelongDate: parseDate(new Date(lastDay)) + ' 23:59:59',
					userUuid: this.getPatientDetailUuid
				}
			}).then(res => {
				if (res.data.code === 200) {
					// console.log(JSON.stringify(res.data.data));
					this.dialogMonthFlag = true;
					this.dialogInfustionMonth = this.monthSel;
					this.dialogInfustionMonthTotal = res.data.data.totalPerDate;

					let chartMonthData = [];
					let totalDate = this.getMonthDays(currDate.getFullYear(), currDate.getMonth() + 1);
					for (let i = 0; i < totalDate; i++) {
						this.oneMonthArr.push(i + 1);
						chartMonthData.push(currDate.getFullYear() + "-" + ((currDate.getMonth() + 1) + '')
							.padStart(2, 0) + "-" + ((i + 1) + '').padStart(2, 0));
					}

					for (let j = 0; j < chartMonthData.length; j++) {
						let isHas = false;
						res.data.data.datas.forEach(inD => {
							if (chartMonthData[j] === inD.belongDate) {
								this.monthData.push(inD.totalPerDate);
								isHas = true;
							}
						});

						if (!isHas) {
							this.monthData.push(0.0);
						}
					}

					this.$nextTick(function() {
						this.drawMonth();
					});
				}
			});
		},
		getMonthDays(year, month) {
			//当天数为0 js自动处理为上一月的最后一天
			let thisDate = new Date(year, month, 0);
			return thisDate.getDate();
		},
		doDetail(row) {
			let vm = this;
			vm.oneDayVals = [];
			vm.dialogFlag = true;
			vm.dialogInfustionDate = row.belongDate;
			vm.dialogInfustionDayTotal = row.totalPerDate;
			vm.dialogInfustionLastTime = getTime(row.infusionTime);
			vm.$axios({
				method: "get",
				url: "/api/web/patientInfusionDataDetail/findByInfusionDataUuid/" + row.uuid
			}).then(res => {
				// console.log("res", res);
				if (res.data.code === 200) {
					// 准备数据
					if (res.data.data !== null && res.data.data.length > 0) {
						vm.oneDayArr.forEach(t => {
							let isHas = false;
							res.data.data.forEach(inD => {
								if (t === getTime(inD.infusionTime)) {
									vm.oneDayVals.push(inD.infusionDataDose);
									isHas = true;
								}
							});

							if (!isHas) {
								vm.oneDayVals.push(0.0);
							}
						});
					}
					console.log(res.data.data)
					vm.$nextTick(function() {
						vm.draw();
					});
				} else {
					vm.$message({
						message: "输注数据获取失败",
						type: "error",
					});
				}
			});
		},
		draw() {
			let chartOptions = {
				title: {
					text: ''
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						return "时间：" + params[0].name + '<br/>' + "输注量：" + params[0].data + "ug";
					}
				},
				xAxis: {
					data: this.oneDayArr,
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					data: this.oneDayVals,
					type: 'bar',
					barCategoryGap: '70%',
					showBackground: true,
					itemStyle: {
						color: '#409EFF'
					},
					label: {
						show: true,
						position: 'top',
						formatter: function (params) {
							if (params.data > 0) {
								return params.data;
							} else {
								return "";
							}
						}
					},
				}]
			};
			let echartsById = this.$refs.placeholder;
			this.charts = this.$echarts.init(echartsById);
			this.charts.clear();
			this.charts.setOption(chartOptions);
		},
		drawMonth() {
			let chartOptions = {
				title: {
					text: ''
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						return "日期：" + params[0].name + '日' + '<br/>' + "输注量：" + params[0].data + "ug";
					}
				},
				xAxis: {
					data: this.oneMonthArr,
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					data: this.monthData,
					type: 'bar',
					barCategoryGap: '70%',
					showBackground: true,
					itemStyle: {
						color: '#409EFF'
					},
					label: {
						show: true,
						position: 'top',
						formatter: function (params) {
							if (params.data > 0) {
								return params.data;
							} else {
								return "";
							}
						}
					},
				}]
			};
			let monthChartId = this.$refs.monthChart;
			this.monthChart = this.$echarts.init(monthChartId);
			this.monthChart.clear();
			this.monthChart.setOption(chartOptions);
		}
	}
};
