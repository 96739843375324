<template>
	<div class="PituitaryPumplist">
		<div class="conditionArea">
			<el-form :data="infusionCsiiCondition" ref="AlarmRecord">
				<span>输注记录</span>
				<el-form-item label="输注日期:" class="conditionAreaForm inner_data">
					<el-date-picker
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						v-model="infusionCsiiBelogDates"
					></el-date-picker>
					<el-button type="primary" style="margin:0 20px;background-color:#4870FF;" @click="doCsiiInfusionSearch">查询</el-button>
					<el-date-picker
						v-model="monthCsiiSel"
						type="month"
						size="mini"
						format="yyyy-MM"
						value-format="yyyy-MM"
						placeholder="选择月份"
						style="width: 110px"
					></el-date-picker>
					<el-button type="primary" style="margin-left: 20px;background-color:#4870FF;" @click="doCsiiMonthSearch">月报告查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="Listmain">
			<el-table style="width: 100%" :data="infusionCsiiData" ref="AlarmRecord">
				<el-table-column label="序号" type="index" min-width="80"></el-table-column>
				<el-table-column label="输注日期" prop="belongDate" min-width="200">
					<template slot-scope="scope">
						<span>{{ scope.row.infusionTime | splitDate }}</span>
					</template>
				</el-table-column>
				<el-table-column label="日总量" prop="totalPerDate" min-width="200">
					<template slot-scope="scope">
						<span>{{ scope.row.infusionDataTotal }}U</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="text" plain @click="doCsiiDetail(scope.row)" style="color:#4870FF;">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				@size-change="handleSizeCsiiChange"
				@current-change="handleCsiiCurrentChange"
				:current-page="infusionCsiiCondition.page"
				:page-sizes="[5, 10, 20, 50]"
				:page-size="infusionCsiiCondition.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="infusionCsiiTotal"
			></el-pagination>
		</div>
		<div>
			<el-dialog :lock-scroll="false" :title="dialogTitle" :visible.sync="dialogFlag" width="80%" :close-on-click-modal="false">
				<div>
					<el-row :gutter="24">
						<el-col :span="8">日期：<b>{{ dialogInfustionDate }}</b></el-col>
						<el-col :span="8">日总量：<b>{{ dialogInfustionDayTotal }}U</b></el-col>
					</el-row>
					<el-row :gutter="24">
						<div ref="placeholder" style="width: 100%;height: 400px;"></div>
					</el-row>
				</div>
			</el-dialog>
		</div>
		<div>
			<el-dialog :lock-scroll="false" title="输注记录月报告" :visible.sync="dialogMonthFlag" width="80%" :close-on-click-modal="false">
				<div>
					<el-row :gutter="24">
						<el-col :span="6">月份：<b>{{ dialogInfustionMonth }}</b></el-col>
						<el-col :span="6">月总量：<b>{{ monthDataSum }}U</b></el-col>
					</el-row>
					<el-row :gutter="24"><div ref="monthChart" style="width: 100%;height: 400px;"></div></el-row>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { splitDate } from '../../filters/filters.js';
import {getTime} from "../../utils/index.js";
import { parseDate } from '../../utils/convert.js';
export default {
	name: 'PatientInfusionDataChart',
	data() {
		return {
			infusionCsiiCondition: {
				page: 1,
				size: 5,
				userUuid: '',
				startInfusionTime: '',
				endInfusionTime: ''
			},
			infusionCsiiTotal: 0,
			infusionCsiiBelogDates: [],
			monthCsiiSel: '',

			infusionCsiiData: [],
			dialogTitle: '日输注记录',
			dialogInfustionDayTotal: 0,
			dialogFlag: false,
			oneDayArr: [
				'00:00',
				'01:00',
				'02:00',
				'03:00',
				'04:00',
				'05:00',
				'06:00',
				'07:00',
				'08:00',
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
				'19:00',
				'20:00',
				'21:00',
				'22:00',
				'23:00'
			],
			oneDayVals: [],
			dialogInfustionDate: '',
			oneMonthArr: [],
			monthData: [],
			dialogMonthFlag: false,
			dialogInfustionMonth: '',
			monthDataSum:0,
		};
	},
	props: {
		puid: String
	},
	created() {
		this.getCsiiInfusionData();
	},
	methods: {
		doCsiiInfusionSearch() {
			this.infusionCsiiData.splice(0, this.infusionCsiiData.length);
			if (this.infusionCsiiBelogDates !== null && this.infusionCsiiBelogDates.length > 1) {
				this.infusionCsiiCondition.startInfusionTime = this.infusionCsiiBelogDates[0] + ' 00:00:00';
				this.infusionCsiiCondition.endInfusionTime = this.infusionCsiiBelogDates[1] + ' 23:59:59';
			} else {
				this.infusionCsiiCondition.startInfusionTime = '';
				this.infusionCsiiCondition.endInfusionTime = '';
			}
			this.getCsiiInfusionData();
		},
		doCsiiMonthSearch() {
			if (this.monthCsiiSel === '') {
				this.$message({
					message: '请选择月份',
					type: 'error'
				});
				return;
			}
			this.monthDataSum=0;
			this.oneMonthArr = [];
			this.monthData = [];
			let currDate = new Date(this.monthCsiiSel + '-01');
			currDate.setMonth(currDate.getMonth() + 1);
			let lastDay = currDate.setDate(0);
			this.$axios({
				method: 'post',
				url: '/api/web/patientCsiiInfusionData/findByMonth',
				data: {
					startInfusionTime: parseDate(new Date(this.monthCsiiSel + '-01')) + ' 00:00:00',
					endInfusionTime: parseDate(new Date(lastDay)) + ' 23:59:59',
					userUuid: this.puid
				}
			}).then(res => {
				if (res.data.code === 200) {

					console.log('month', res.data.data);

					this.dialogMonthFlag = true;
					this.dialogInfustionMonth = this.monthCsiiSel;
					let chartMonthData = [];
					let totalDate = this.getMonthDays(currDate.getFullYear(), currDate.getMonth() + 1);
					for (let i = 0; i < totalDate; i++) {
						this.oneMonthArr.push(i + 1);
						chartMonthData.push(currDate.getFullYear() + '-' + (currDate.getMonth() + 1 + '').padStart(2, 0) + '-' + (i + 1 + '').padStart(2, 0));
					}
					for (let j = 0; j < chartMonthData.length; j++) {
						let isHas = false;
						res.data.data.forEach(inD => {
							console.log('chartMonthData[j]', chartMonthData[j]);
							console.log('infusionTime', splitDate(inD.infusionTime));
							if (chartMonthData[j] === splitDate(inD.infusionTime)) {
								this.monthData.push(inD.infusionDataDose);
								isHas = true;
							}
						});

						if (!isHas) {
							this.monthData.push(0.0);
						}
					}
					for(var i = 0; i < this.monthData.length; i++){
						this.monthDataSum = this.monthData[i] + this.monthDataSum;
					}
					this.monthDataSum = Math.floor(this.monthDataSum * 10) / 10;
					this.$nextTick(function() {
						this.drawMonth();
					});
				}
			});
		},
		getMonthDays(year, month) {
			//当天数为0 js自动处理为上一月的最后一天
			let thisDate = new Date(year, month, 0);
			return thisDate.getDate();
		},
		getCsiiInfusionData() {
			let vm = this;
			vm.infusionCsiiCondition.userUuid = vm.puid;
			vm.$axios({
				method: 'post',
				url: '/api/web/patientCsiiInfusionData/page',
				data: vm.infusionCsiiCondition
			}).then(res => {
				console.log('infusionCsiiData', res.data);
				if (res.data.code === 200) {
					vm.infusionCsiiData = res.data.data;
					vm.infusionCsiiTotal = res.data.pageData.totalElements;
				} else {
					vm.$message({
						message: '输注数据获取失败',
						type: 'error'
					});
				}
			});
		},
		doCsiiDetail(row) {
			let vm = this;
			vm.dialogFlag = true;
			vm.dialogInfustionDate = splitDate(row.infusionTime);
			vm.dialogInfustionDayTotal = row.infusionDataTotal;
			vm.oneDayVals= [];

			var data = {
				endInfusionTime:splitDate(row.infusionTime) + ' 59:59:59',
				startInfusionTime:splitDate(row.infusionTime) + ' 00:00:00',
				userUuid: vm.puid
			}

			vm.$axios({
				method: 'post',
				url: '/api/web/patientCsiiInfusionData/list',
				data: data
			}).then(res => {
				if (res.data.code === 200) {
					// 准备数据
					if (res.data.data !== null && res.data.data.length > 0) {
						console.log('infusionCsiiData', res.data.data);
						vm.oneDayArr.forEach(t => {
							let isHas = false;
							res.data.data.forEach(inD => {
								if (t === getTime(inD.infusionTime)) {
									// vm.oneDayVals.push(inD.infusionDataTotal);
									vm.oneDayVals.push(inD.infusionDataDose);
									isHas = true;
								}
							});

							if (!isHas) {
								vm.oneDayVals.push(0.0);
							}
						});
					}

					vm.$nextTick(function() {
						vm.draw();
					});
				} else {
					vm.$message({
						message: '输注数据获取失败',
						type: 'error'
					});
				}
			});
		},
		draw() {
			let chartOptions = {
				title: {
					text: ''
				},
				xAxis: {
					data: this.oneDayArr,
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					data: this.oneDayVals,
					type: 'bar',
					barCategoryGap: '70%',
					showBackground: true,
					itemStyle: {
						color: '#409EFF'
					},
					label: {
						show: true,
						position: 'top',
						formatter: function (params) {
							if (params.data > 0) {
								return params.data;
							} else {
								return "";
							}
						}
					},
				}],
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						return "时间：" + params[0].name + '<br/>' + "输注量：" + params[0].data + "U";
					}
				},
			};
			let echartsById = this.$refs.placeholder;
			this.charts = this.$echarts.init(echartsById);
			this.charts.clear();
			this.charts.setOption(chartOptions);
		},
		drawMonth() {
			let chartOptions = {
				title: {
					text: ''
				},
				xAxis: {
					data: this.oneMonthArr,
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: this.monthData,
						type: 'bar',
						barCategoryGap: '70%',
						showBackground: true,
						itemStyle: {
							color: '#409EFF'
						},
						label: {
							show: true,
							position: 'top',
							formatter: function (params) {
								if (params.data > 0) {
									return params.data;
								} else {
									return "";
								}
							}
						},
					}
				],
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						return "日期：" + params[0].name + '日' + '<br/>' + "输注量：" + params[0].data + "U";
					}
				},
			};
			let monthChartId = this.$refs.monthChart;
			this.monthChart = this.$echarts.init(monthChartId);
			this.monthChart.clear();
			this.monthChart.setOption(chartOptions);
		},
		// 输注记录 监听 pagesize 改变的事件
		handleSizeCsiiChange(newSize) {
			this.infusionCsiiCondition.size = newSize;
			this.getCsiiInfusionData();
		},
		//  输注记录 监听 页码值 改变的事件
		handleCsiiCurrentChange(newPage) {
			this.infusionCsiiCondition.page = newPage;
			this.getCsiiInfusionData();
		}
	}
};
</script>

<style scoped>
.PituitaryPumplist {
	background-color: #fff;
	height: auto;
	padding-bottom: 20px;
	margin-top: 16px;
	border-radius: 10px;
}
.conditionArea {
	width: 100%;
	float: left;
}
.conditionArea span {
	float: left;
	line-height: 60px;
	font-weight: bold;
	margin-left: 30px;
}
.conditionAreaForm {
	float: right;
	margin-bottom: 0 !important;
	margin-right: 20px;
}
/deep/ .el-table td:first-child,
.el-table th:first-child {
	text-align: center !important;
}
.el-dialog__header {
	text-align: left !important;
}
.el-dialog__title {
	text-align: left !important;
}
</style>
