<template>
    <div class="PituitaryPumplist">
        <div class="conditionArea">
            <el-form :data="csiiAlarmRecord" ref="AlarmRecord">
                <span>报警记录</span>
                <el-form-item label="报警日期:" class="conditionAreaForm inner_data">
                    <el-date-picker
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            v-model="warningDates"
                    ></el-date-picker>
                    <el-button type="primary" style="margin-left: 20px;background-color:#4870FF;" @click="doCsiiWarningSearch">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="Listmain">
            <el-table style="width: 100%" :data="csiiAlarmRecord" ref="AlarmRecord">
                <el-table-column label="序号" type="index" min-width="50"></el-table-column>
                <el-table-column label="报警时间" prop="warningTime" min-width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.warningTime | splitDateTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="报警原因" prop="warningReason" min-width="200">
                    <template slot-scope="scope">
                        <!--									<span v-if="scope.row.warningReason === 1">电量耗尽</span>-->
                        <span v-if="scope.row.warningReason === 2">超日总量</span>
                        <span v-if="scope.row.warningReason === 3">药量低</span>
                        <span v-if="scope.row.warningReason === 4">低电量</span>
                        <span v-if="scope.row.warningReason === 5">电量尽</span>
                        <span v-if="scope.row.warningReason === 6">药尽</span>
                        <span v-if="scope.row.warningReason === 7">阻塞</span>
                        <span v-if="scope.row.warningReason === 8">输注关</span>
                        <span v-if="scope.row.warningReason === 9">按键卡主</span>
                        <span v-if="scope.row.warningReason === 10">编码器故障</span>
                        <span v-if="scope.row.warningReason === 11">主控故障</span>
                        <span v-if="scope.row.warningReason === 12">点击故障</span>
                        <span v-if="scope.row.warningReason === 13">内存故障</span>
                        <span v-if="scope.row.warningReason === 14">通信故障</span>
                        <span v-if="scope.row.warningReason === 15">时钟故障</span>
                        <span v-if="scope.row.warningReason === 16">充盈</span>
                        <span v-if="scope.row.warningReason === 17">复位</span>
                    </template>
                </el-table-column>
                <el-table-column label="报警描述" prop="warningDesc" min-width="200"></el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="warningData.page"
                    :page-sizes="[5, 10, 20, 50]"
                    :page-size="warningData.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="warningDataTotal"
                    style="text-align: right;margin-right: 10px;margin-top: 5px;"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PatientCsiiWarningRecords",
        data() {
            return {
                csiiAlarmRecord: [],
                warningDates: [],
                warningData: {
                    page: 1,
                    size: 5,
                    deviceType: 2,
                    userUuid: '',
                    warningStartDate: '',
                    warningEndDate: ''
                },
                warningDataTotal: 0
            }
        },
        props: {
            puid: String
        },
        created() {
            this.getAlarmRecord();
        },
        methods: {
            doCsiiWarningSearch() {
                if (this.warningDates !== null && this.warningDates.length > 1) {
                    this.warningData.warningStartDate = this.warningDates[0] + ' 00:00:00';
                    this.warningData.warningEndDate = this.warningDates[1] + ' 23:59:59';
                } else {
                    this.warningData.startBelongDate = '';
                    this.warningData.endBelongDate = '';
                }
                this.getAlarmRecord();
            },
            // 报警记录  监听 pagesize 改变的事件
            handleSizeChange(newSize) {
                this.warningData.size = newSize;
                this.getAlarmRecord()
            },
            // 报警记录  监听 页码值 改变的事件
            handleCurrentChange(newPage) {
                this.warningData.page = newPage;
                this.getAlarmRecord()
            },
            getAlarmRecord() {
                this.csiiAlarmRecord.splice(0, this.csiiAlarmRecord.length); //清空数组
                this.warningData.userUuid = this.puid;
                this.$axios({
                    method: "post",
                    url: "/api/web/patientWarning/page", // 接口地址
                    data: this.warningData
                }).then(res => {
                    if (res.data.code === 200) {
                        this.csiiAlarmRecord = res.data.data;
                        this.totalPages = res.data.pageData.totalPages;
                        this.warningDataTotal = res.data.pageData.totalElements;
                    } else {
                        this.$message({
                            message: "操作失败",
                            type: "error",
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    //this.$message.error("请求失败");
                });
            },
        }
    }
</script>

<style scoped>
    .PituitaryPumplist {
        background-color: #fff;
        height: auto;
        padding-bottom: 20px;
        margin-top: 16px;
        border-radius: 10px;
    }
    .conditionArea {
        width: 100%;
        float: left;
    }
    .conditionArea span {
        float: left;
        line-height: 60px;
        font-weight: bold;
        margin-left: 30px;
    }
    .conditionAreaForm {
        float: right;
        margin-bottom: 0 !important;
        margin-right: 20px;
    }
    /deep/.conditionAreaForm .el-input__inner {
        width: 150px;
    }
    /deep/.inner_data .el-input__inner {
        width: 300px;
    }
    /deep/.el-table td:first-child,
    .el-table th:first-child {
        text-align: center !important;
    }
</style>