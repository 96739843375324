<template>
	<div class="PituitaryPumplist">
		<div class="conditionArea">
			<el-form ref="AlarmRecord" :data="medicalRecordCondition">
				<span>就医记录</span>
				<el-form-item label="就诊日期:" class="conditionAreaForm inner_data">
					<el-date-picker
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						v-model="visitDates"
					></el-date-picker>
					<el-button type="primary" style="margin-left: 20px;background-color:#4870FF;" @click="doMedicalSearch">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="Listmain">
			<el-table style="width: 100%" :data="medicalRecordData" border>
				<el-table-column label="序号" type="index" min-width="80"></el-table-column>
				<el-table-column label="就诊日期" prop="visitDate" min-width="100" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.visitDate | splitDate }}</span>
					</template>
				</el-table-column>
				<el-table-column label="就诊医院" prop="hospitalName" min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="诊断意见" prop="commit" min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="治疗方案" prop="treatment" min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column :label="'促卵泡生产激素\n（单位FSH）'" prop="verificationResult1" min-width="110" align="center"></el-table-column>
				<el-table-column :label="'促黄体生产激素\n（单位LH）'" prop="verificationResult2" min-width="110" align="center"></el-table-column>
				<el-table-column :label="'雌二醇\n（单位E2）'" prop="verificationResult3" min-width="100" align="center"></el-table-column>
				<el-table-column :label="'孕酮\n（单位P）'" prop="verificationResult4" min-width="100" align="center"></el-table-column>
				<el-table-column :label="'催乳激素\n（单位PRL）'" prop="verificationResult5" min-width="110" align="center"></el-table-column>
				<el-table-column :label="'睾酮\n（单位T）'" prop="verificationResult6" min-width="80" align="center"></el-table-column>
				<el-table-column label="常染色体检查" prop="verificationResult7" min-width="80" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.verificationResult7 === 1"><el-tag type="success" size="small">正常</el-tag></span>
						<span v-if="scope.row.verificationResult7 === 0"><el-tag type="danger" size="small">异常</el-tag></span>
					</template>
				</el-table-column>
				<el-table-column label="填写时间" prop="createTime" min-width="100" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime | splitDate }}</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="medicalRecordCondition.page"
				:page-sizes="[5, 10, 20, 50]"
				:page-size="medicalRecordCondition.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="gnrhMedicalTotal"
				style="text-align: right;margin-right: 10px;margin-top: 5px;"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PatientGnrhMedicals',
	data() {
		return {
			medicalRecordCondition: {
				page: 1,
				size: 5,
				userUuid: this.puid,
				endVisitDate: '',
				startVisitDate: ''
			},

			medicalRecordData: [],
			gnrhMedicalTotal: 0,
			visitDates: []
		};
	},
	props: {
		puid: String
	},
	created() {
		this.getGnrhMdicalRecordList();
	},
	methods: {
		doMedicalSearch() {
			this.medicalRecordData.splice(0, this.medicalRecordData.length);
			if (this.visitDates !== null && this.visitDates.length > 1) {
				this.medicalRecordCondition.startVisitDate = this.visitDates[0] + ' 00:00:00';
				this.medicalRecordCondition.endVisitDate = this.visitDates[1] + ' 23:59:59';
			} else {
				this.medicalRecordCondition.startVisitDate = '';
				this.medicalRecordCondition.endVisitDate = '';
			}
		},
		getGnrhMdicalRecordList() {
			this.$axios.post('/api/web/patientGnrhMedicalRecord/page', this.medicalRecordCondition).then(res => {
				if (res.data.code === 200) {
					this.medicalRecordData = res.data.data;
					this.gnrhMedicalTotal = res.data.pageData.totalElements;
				} else {
					this.$message({
						message: '就诊记录获取失败',
						type: 'error'
					});
				}
			});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.medicalRecordCondition.size = newSize;
			this.getGnrhMdicalRecordList();
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.medicalRecordCondition.page = newPage;
			this.getGnrhMdicalRecordList();
		}
	}
};
</script>

<style scoped>
.PatientInformation {
	height: 60px;
	border-radius: 10px;
	background-color: #fff;
}
/deep/.PatientInformationForm {
	margin-bottom: 0 !important;
	margin-left: 30px;
	float: left;
	display: block;
}
/deep/.el-form-item--small .el-form-item__label {
	line-height: 60px;
	float: left;
}
/deep/.el-form-item--small .el-form-item__content {
	line-height: 60px;
	float: left;
}
.PituitaryPumplist {
	background-color: #fff;
	height: auto;
	padding-bottom: 20px;
	margin-top: 16px;
	border-radius: 10px;
}
.conditionArea {
	width: 100%;
	float: left;
}
.conditionArea span {
	float: left;
	line-height: 60px;
	font-weight: bold;
	margin-left: 30px;
}
.conditionAreaForm {
	float: right;
	margin-bottom: 0 !important;
	margin-right: 20px;
}
/deep/.conditionAreaForm .el-input__inner {
	width: 150px;
}
/deep/.inner_data .el-input__inner {
	width: 300px;
}
/deep/.el-table td:first-child,
.el-table th:first-child {
	text-align: center !important;
}
/deep/.el-table .cell{
	white-space: pre-line;
}
</style>
