<template>
	<div class="PituitaryPumplist">
		<div class="conditionArea">
			<el-form ref="AlarmRecord" :data="infusionPlanCondition">
				<span>输注方案</span>
				<el-form-item label="方案名称:" class="conditionAreaForm">
					<el-input clearable v-model="infusionPlanCondition.name" placeholder="请输入用户名"></el-input>
					<el-button type="primary" style="margin-left: 20px;background-color:#4870FF;" @click="getPlanList">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="Listmain">
			<el-table style="width: 100%" :data="infusionPlanData" ref="AlarmRecord">
				<el-table-column label="序号" type="index" min-width="150"></el-table-column>
				<el-table-column label="方案名称" prop="name" min-width="100"></el-table-column>
				<el-table-column label="输注间隔（单位min）" prop="duration" min-width="100"></el-table-column>
				<el-table-column label="单次剂量（单位ug）" prop="perDose" min-width="100"></el-table-column>
				<el-table-column label="状态" prop="isSel" min-width="150">
					<template slot-scope="scope">
						<span v-if="scope.row.isSel === 1"><el-tag type="success" size="small">在用</el-tag></span>
						<span v-if="scope.row.isSel !== 1"><el-tag type="danger" size="small">未在用</el-tag></span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="text" plain @click="doVfdDetail(scope.row)" style="color:#4870FF;">查看变频</el-button>
						<el-button size="mini" type="text" plain @click="doChart(scope.row)" style="color:#4870FF;">查看图表</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="infusionPlanCondition.page"
				:page-sizes="[5,10, 20, 50]"
				:page-size="infusionPlanCondition.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="planTotal"
				style="text-align: right;margin-right: 10px;margin-top: 5px;"
			></el-pagination>
		</div>

		<div>
			<el-dialog :lock-scroll="false" :title="dialogChartTitle" :visible.sync="dialogChartFlag" 
				width="90%" :close-on-click-modal="false">
				<div ref="placeholderPlan" style="width: 100%;height: 400px;"></div>
			</el-dialog>
		</div>

		<div>
			<el-dialog :lock-scroll="false" :title="dialogVfdTitle" :visible.sync="dialogVfdFlag" width="40%" :close-on-click-modal="false">
				<el-table style="width: 70%;text-align: center;margin: 0 auto;" :data="planVfdData">
					<el-table-column label="序号" type="index" min-width="20"></el-table-column>
					<el-table-column label="时间点" prop="time" min-width="10"></el-table-column>
					<el-table-column label="单次剂量（单位ug）" prop="perInfusion" min-width="10"></el-table-column>
				</el-table>
			</el-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PatientPlan',
	data() {
		return {
			infusionPlanCondition: {
				page: 1,
				size: 5,
				name: '',
				userUuid: this.puid
			},
			infusionPlanData: [],
			planTotal: 0,

			dialogChartTitle: '方案图表',
			dialogChartFlag: false,
			chartsPlan: {},
			oneDayPlanArr: [],
			oneDayPlanVals: [],
			oneDayArr: [
				"00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45",
				"02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45",
				"04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
				"06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45",
				"08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45",
				"10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
				"12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45",
				"14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45",
				"16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
				"18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45",
				"20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45",
				"22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"
			],
			dialogVfdTitle: '变频参数',
			dialogVfdFlag: false,
			planVfdData: []
		};
	},
	props: {
		puid: String
	},
	created() {
		this.getPlanList();
	},
	methods: {
		getPlanList() {
			this.$axios.post('/api/web/patientInfusionPlan/page', this.infusionPlanCondition).then(res => {
				if (res.data.code === 200) {
					this.infusionPlanData = res.data.data;
					this.planTotal = res.data.pageData.totalElements;
				} else {
					this.$message({
						message: '输注方案获取失败',
						type: 'error'
					});
				}
			});
		},
		doVfdDetail(row) {
			let that = this;
			that.dialogVfdFlag = true;
			that.$axios.get('/api/web/patientInfusionPlanVfd/findByPlanUuid/' + row.uuid).then(res => {
				if (res.data.code === 200) {
					that.planVfdData = res.data.data;
				}
			});
		},
		draw() {
			let that = this;
			let chartOptions = {
					title: { 
						text: '' 
					},
					grid: {
						left: '1%',
						right: '1%',
						containLabel: true
					},
					xAxis: {
						data: that.oneDayArr,
						axisTick: {
							show: true,
							alignWithLabel: true
						}
					},
					yAxis: {
						type: 'value'
					},
					series: [
						{
							data: that.oneDayPlanArr,
							type: 'bar',
							barCategoryGap: '60%',
							showBackground: true,
							itemStyle: { color: '#409EFF' },
							label: {
								show: false,
								position: 'top',
								formatter: function (params) {
									if (params.data > 0) {
										return params.data;
									} else {
										return "";
									}
								}
							},
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: function (params) {
							return "时间：" + params[0].name + '<br/>' + "输注量：" + params[0].data + "ug";
						}
					},
				};

				let echartsPlanById = this.$refs.placeholderPlan;
				console.log('echartsPlanById', echartsPlanById);
				that.chartsPlan = that.$echarts.init(echartsPlanById);
				that.chartsPlan.clear();
				that.chartsPlan.setOption(chartOptions);
		},
		doChart(row) {
			let that = this;
			that.oneDayPlanArr = [];
			that.dialogChartFlag = true;
			console.log('row', row)
			if (row.dosePoint != "") {
				that.oneDayPlanArr = row.dosePoint.split(',');
				console.log('that.oneDayPlanArr', that.oneDayPlanArr);
				this.$nextTick(function() {
					this.draw();
				});
			} else {

				for (let i = 0; i < (60*24/row.duration); i++) {
					that.oneDayPlanArr.push(row.perDose);
				}

				this.$nextTick(function() {
					this.draw();
				});
			}
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.infusionPlanCondition.size = newSize;
			this.getPlanList();
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.infusionPlanCondition.page = newPage;
			this.getPlanList();
		}
	}
};
</script>

<style scoped>
.PatientInformation {
	height: 60px;
	border-radius: 10px;
	background-color: #fff;
}
/deep/.PatientInformationForm {
	margin-bottom: 0 !important;
	margin-left: 30px;
	float: left;
	display: block;
}
/deep/.el-form-item--small .el-form-item__label {
	line-height: 60px;
	float: left;
}
/deep/.el-form-item--small .el-form-item__content {
	line-height: 60px;
	float: left;
}
.PituitaryPumplist {
	background-color: #fff;
	height: auto;
	padding-bottom: 20px;
	margin-top: 16px;
	border-radius: 10px;
}
.conditionArea {
	width: 100%;
	float: left;
}
.conditionArea span {
	float: left;
	line-height: 60px;
	font-weight: bold;
	margin-left: 30px;
}
.conditionAreaForm {
	float: right;
	margin-bottom: 0 !important;
	margin-right: 20px;
}
/deep/.conditionAreaForm .el-input__inner {
	width: 150px;
}
/deep/.el-table td:first-child,
.el-table th:first-child {
	text-align: center !important;
}
/deep/.el-input{
	width: auto;
}
</style>
